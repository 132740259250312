import React, { useState, useEffect } from 'react'
import loadable from '@loadable/component'

const QuoteModalContent = loadable(() => import('./quote-modal-content'));

const QuoteModal = ({open=false}) => {
  let [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    setIsOpen(open)
  }, [open])
  function closeModal() {
    setIsOpen(false)
  }

  return (
    <>
      <div className='fixed top-0 left-0 right-0 z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full flex' style={{backgroundColor: 'rgba(0, 0, 0, .5)', display: isOpen? 'block': 'none'}}>
        <QuoteModalContent closeModal={closeModal} />
      </div>
    </>
  )
}

export default QuoteModal
